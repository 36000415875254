<template>
  <span>
    <span v-if="precise">
      {{ c }}
    </span>
    <span v-else>
      <numAbv :num="c" />
    </span>
  </span>
</template>
<script>
import numAbv from "@/components/e/numAbv.vue";
export default {
  //name: 'Home',
  components: {
    numAbv, //bottomBar,
  },
  data() {
    return {
      c: "", //loading char...
    };
  },
  props: ["id", "placeholder", "precise"],
  methods: {
    validateUrl(event) {
      //duh
    },
    fetchData(event) {
      //alert('fetchData' + this.id)
      this.loading = true;
      this.c = this.placeholder || ""; //remove
      window.API.getFollowersCount(this.id).then((res) => {
        // console.log('getFollowersCount', res.data);
        this.c = res.data;
        this.loading = false;
      });
    },
  },
  watch: {
    id: function (from, to) {
      //necessary to refresh on url changes.
      this.fetchData();
    },
  },
  mounted() {
    /* to be executed when mounted */
    this.fetchData();
  },
};
</script>

<style></style>
