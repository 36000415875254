<template>
  <span class="num">
    {{ output }}
  </span>
</template>
<script>
//import Navbar from '../_ext/popup/components/Navbar'; //NEED REFACTORING!
import { abbreviateNumber } from "js-abbreviation-number";

export default {
  name: "num",
  components: {
    //  Navbar: Navbar,
  },
  props: ["num", "digit"],
  data() {
    return {
      //output: '',
      isHtml: false,
    };
  },
  computed: {
    output: function (e) {
      if (!this.num) return 0;
      try {
        var n = parseInt(this.num) || 0;
        var d = this.digit || 0;
        return abbreviateNumber(n, d); // expected 1.0K
      } catch (e) {
        return 0;
      }
    },
    openLoginModal: function (event) {
      return false;
      //this.store.openLoginModal();// trigger in BG via Store.
    },
  },
  watch: {
    nu: function (from, to) {
      //this.loadPost();
    },
  },
  /*mounted() {
  this.render()

},*/
};
</script>
