<template>
  <div class="" v-if="item && item.id">
    <jsonDebug :data="item" />

    <div class="card item" :class="{ expanded: true, expanded2: true }">
      <!--
      {{ item.score }}
AI search...

       -->
      <div class="whitefill" @click="clickToExpand">
        <!-- TODO: AMKE CARDFEED COMPONENT" -->
        <div class="card-content">
          <div class="item-header">
            <!-- 
               @dblclick="itemDblCkick(item._id)"

                <img :src="item.gravatar" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px" />
   -->
            <div class="from-block">
              <router-link :to="authorUrl" class="" avat>
                <img
                  :src="item.avatar"
                  class="avatar"
                  loading="lazy"
                  style="float: left; margin-right: 10px; width: 44px; height: 44px"
                />
                <!--    <Avatar
                  :fullname="item.parsed.from.name || item.parsed.from.address"
                  :email="item.parsed.from.address"
                  :fromTld="item.fromTld"
                  :size="38"
                  style="float: left; margin-right: 10px"
                />
              
                  <img
                    v-if="!item.majorWebmail"
                    :src="`https://www.google.com/s2/favicons?domain=${item.fromTld}&sz=128`"
                    style="width: 38px; height: 38px; float: left; margin-right: 10px"
                  />
  
                  <avatar
                    v-if="item.majorWebmail"
                    :fullname="item.parsed.from.name || item.parsed.from.address"
                    :size="38"
                    style="float: left; margin-right: 10px"
                  />  -->
              </router-link>
              <span class="item-from" style="">
                <router-link :to="authorUrl" class="authorName">
                  {{ item.bot }}
                  <!-- 
                  <b-tooltip :label="item.url" type="is-dark" position="is-right" :delay="1000">
                    <span>
                      <small> {{ item.bot }}</small>
                    </span>
                  </b-tooltip> -->
                </router-link>

                <!-- 
                <b-tooltip :label="item.handle || 333" type="is-dark" position="is-right" :delay="1000">
                  <span @click.stop="clickAddress(item.handle)">
                    <small> {{ item.bot }} xxx3333</small>
                  </span>
                </b-tooltip>
              -->

                <small class="timeago">
                  {{ timeAgo(item.created) }}

                  <span v-if="item.blueprint"
                    >•

                    <router-link :to="item.blueprintUrl" class="blueprintName">
                      {{ item.blueprintLabel }}
                    </router-link>
                    by
                    <!-- 
                    <router-link :to="'/u/' + item.user" class="blueprintName">
                      {{ item.user }}
                    </router-link>

                    +++ -->
                    <ProfileCardHover :user="item.user" position="is-bottom-left" style="display: inline">
                      <a> {{ item.user }} </a>
                    </ProfileCardHover>
                  </span>
                </small>

                <!-- 
                <b-tooltip :label="item.date" type="is-dark" :delay="2000">
                  <span>
                    <small>{{ timeAgo(item.created) }}</small>
                  </span>
                </b-tooltip> -->

                <!--  tag with email address sen to
               
                <span class="is-small">
                  <span class="tag is-info is-light is-small" style="margin-left: 10px">{{ item.aa }} </span>
                  <small> +3</small>
                </span>  -->
              </span>
            </div>
            <div style="">
              <DotDrop :horizontal="true" :items="dropDownItems" position="is-bottom-left" />

              <!-- 
              <a :href="getMailtoHref(item)" target="_blank" class="button is-small">
                <strong>FW</strong>
              </a>
  
              <b-button @click="copyLink(item)" icon-pack="far" type="is-text" rounded>
                <i class="fas fa-bucket" aria-hidden="true"></i>
              </b-button> -->

              <b-button v-if="canClose" @click="closeBtn(item._id)" icon-pack="far" type="is-text" rounded>
                <i class="fas fa-times" aria-hidden="true"></i>
              </b-button>
            </div>
          </div>

          <b-loading :is-full-page="false" :active="summerazing" :can-cancel="false"></b-loading>

          <div v-if="isImage" class="images">
            <div v-if="!isDetail">
              <!-- FEED VIEW -->
              <squareImgMosaic :images="images || []" @clickImageInMosaic="clickImageInMosaic" />
              <!--  
              <router-link :to="item.url">
                <b-image
                  :src="featuredImage.url1200"
                  :placeholder="featuredImage.url50"
                  v-if="featuredImage && featuredImage.url"
                  class=" "
                  :style="featuredImage.style"
                  loading="lazy"
                />
              </router-link>
            --></div>
            <div v-if="isDetail">
              <span v-for="i in images" :key="i.url">
                <img :src="i.url" class=" " :style="i.style + ' ; width:100%;'" loading="lazy" />
              </span>
            </div>
            <!-- -->
            <div class="picture-caption content" style="margin-top: 20px" v-if="item.text">
              <router-link :to="authorUrl" style="font-weight: bold; color: black">{{ item.bot }}</router-link>

              <UserText :text="item.text" />
            </div>
          </div>

          <div v-if="isText">
            <div class="item-body">
              <div class="item-text">
                <shadow-root v-if="format == 'html'">
                  <!-- markdown or table rendering --->@@@@@
                  <div class="content" v-html="item.html || item.text"></div>
                </shadow-root>

                <div
                  v-if="format != 'html' && isShortText"
                  class="summary subtitle content is-2"
                  style="margin-top: 20px; color: #333"
                  v-text="item.text"
                ></div>
                <!-- BIG PLAI TEXT, short updates,  .-->

                <div
                  v-if="format != 'html' && !isShortText && isDetail"
                  class=" "
                  style="margin-top: 20px; color: #333"
                  v-text="item.text"
                ></div>

                <p
                  v-if="format != 'html' && !isShortText && !isDetail"
                  class="lineclamp4NO"
                  style="margin: 0; white-space: pre-line"
                >
                  <v-clamp autoresize :max-lines="hasSnipet ? 9 : 3" :expanded="expandText" @clampchange="clampchange"
                    >{{ item.text }}

                    <template #after="{ toggle, expanded, clamped }">
                      <b-button
                        v-if="clamped && !expanded"
                        size="is-small"
                        class="btSeeMore"
                        rounded
                        type="    is-text  "
                        @click.stop="toggle"
                      >
                        See more
                      </b-button>
                    </template>
                  </v-clamp>
                </p>
              </div>
            </div>
          </div>
          <!--  -->
        </div>

        <footer class="card-footer socialCounter" style="" v-if="likeCount || item.commentsCount">
          <!-- download file button with image preview file icon -->
          <span v-if="likeCount">
            <i class="fas fa-heart-circle" style="color: red; font-size: 20px; margin-right: 5px"></i> {{ likeCount }}
          </span>
          <span v-if="item.commentsCount" style="margin-left: 20px">
            <!--  
            <i class="fas fa-comments" style="color: blue; font-size: 20px; margin-right: 5px"></i>

            add s to string if more than 1 comment

            -->
            <a>
              {{ item.commentsCount }}
              <span v-if="item.commentsCount > 1">comments</span>
              <span v-else>comment</span>
            </a>
          </span>
        </footer>

        <footer class="card-footer" v-show="true">
          <a class="card-footer-item bt-like" @click="clickLike" :class="{ loading: loadingLikeButton }">
            <span v-if="isLiked">
              <span class="icon">
                <i class="fas fa-heart" aria-hidden="true"></i>
              </span>
              <span>Liked </span>
            </span>
            <span v-else>
              <span class="icon">
                <i class="far fa-heart" aria-hidden="true"></i>
              </span>
              <span>Like </span>
            </span>
          </a>

          <a v-if="item && item.url && !isDetail" class="card-footer-item" @click="clickComments">
            <span v-if="inlineCommentsOpen" class="icon" key="abb">
              <i class="fas fa-comment" aria-hidden="true"></i>
            </span>
            <span v-else class="icon" key="hh37f"> <i class="far fa-comment" aria-hidden="true"></i> </span>
            <span>Comment </span>
          </a>
          <!-- 
          <router-link :to="item.url" v-if="item && item.url && !isDetail" class="card-footer-item">
            <span class="icon"> <i class="far fa-comment" aria-hidden="true"></i> </span>
            <span>Comment </span>
          </router-link>
 -->
          <a class="card-footer-item" @click="clickShare">
            <span class="icon">
              <i class="far fa-share" aria-hidden="true"></i>
            </span>
            <span>Share </span>
          </a>
        </footer>
        <footer class="card-footer inlineComments" style="padding: 10px" v-if="inlineCommentsOpen">
          <!--  <br />
          COMMENT OWNET LINE teaser... Comment lists. Loading..   -->
          <Comments
            entity="post"
            :id="item.id"
            :authorShortid="item.bot"
            :authorDisplayName="item.bot || item.bot"
            layout="card"
            v-if="item"
          />

          <!--  
          <span v-if="likeCount">
            <i class="fas fa-heart-circle" style="color: red; font-size: 20px; margin-right: 5px"></i> {{ likeCount }}
          </span>
-->
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import faker from 'faker'
//import VuePaginate from "vue-paginate";
/*
  window.inbox = {};
              window.paginate = {
                  total: Math.random() * (54236 - 100) + 3
              }
              for (var i = 0; i <= 10; i++) {
                  window.inbox[i] = {
                      from: faker.name.findName(),
                      timestamp: null,
                      subject: faker.lorem.sentence().substring(0, 40),
                      snippet: faker.lorem.lines(),
                      fullMail: window.faker.lorem.paragraphs(faker.random.number(40)),
                      email: faker.internet.email()
                  };
              }
  */

//export vue compoenn
//import Avatar from "@/components/avatar.vue";

import VClamp from "vue-clamp"; //needs some config.vue setup...https://justineo.github.io/vue-clamp/demo/
//import FileIcon from "./FileIcon.vue";
//import MessageDetailsModal from "@/components/MessageDetailsModal.vue";
// import elipsis from utils
/*
import {
  formatPlainTextInHtml,
  removeExtraSpacesAndLines,
  removeEmailHeaders,
  elipsis,
  timeAgo,
  replaceUrls,
  truncateTextWords,
} from "../common/utils";*/

// DotDrop
import DotDrop from "@/components/e/DotDrop.vue";
import Comments from "@/components/e/comments.vue";
import ProfileCardHover from "@/components/profile/ProfileCardHover.vue";

import squareImgMosaic from "@/components/feed/squareImgMosaic.vue";

import {
  formatPlainTextInHtml,
  removeExtraSpacesAndLines,
  removeEmailHeaders,
  elipsis,
  timeAgo,
  replaceUrls,
  truncateTextWords,
} from "../common/utils";

export default {
  //props: receive "item" from parent
  props: {
    orgId: {
      type: String,
      default: "personal",
    },
    item: {
      type: Object,
      required: true,
    },
    defaultFormat: {
      type: String,
      default: "text",
    },
    context: {
      type: String,
      default: "feed", //could be detail
    },
    multiImageLayout: {
      type: String,
      default: "swipe", //swiper or list or mosaic or single
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    //  Avatar,
    Comments,
    ProfileCardHover,
    VClamp,
    //FileIcon,
    DotDrop,
    squareImgMosaic,
    // MessageDetailsModal,
    // VuePaginate
  },
  //props for messages
  data() {
    return {
      //  messages: [],
      // hasSnipet: this.code || this.action,
      expandText: false,
      showMeta: false,
      showBody: false,
      copied: false,
      newSummary: "",
      summerazing: false,
      format: this.defaultFormat,
      expanded: false, //from preview height
      expanded2: false,
      hasClickedLike: false,
      loadingLikeButton: false,
      inlineCommentsOpen: false,
    };
  },
  //load item from API on load
  mounted() {
    // this.getMessages();
  },
  //watch expanded text
  watch: {
    expandText: function (prev, now) {
      console.log(prev, now);
      var me = this;
      if (now === false) {
        setTimeout(() => {
          //  alert(now + "423432432");
          this.$emit("cardTextExpanded", me); //blocked by swiper js...need to use custom event
          this.$el.dispatchEvent(
            new CustomEvent("cardTextExpanded", { detail: { priority: 3 }, bubbles: true, composed: true })
          );
        }, 10); //need to wait until expansioin is completed
      }

      // this.$emit("expanded", val);
    },
  },
  computed: {
    /* getFormat() {
        return "plain";
        return this.item.parsed.format;
      },*/
    isDetail() {
      return this.context === "detail";
    },
    isFeed() {
      return this.context === "feed";
    },
    isImage() {
      return this.item.featuredImage;
    },
    isText() {
      return !this.item.featuredImage;
    },
    isShortText() {
      if (!this.item.text) return false;
      return this.item.text.length < 100;
    },
    authorUrl() {
      return "/" + this.item.bot;
    },
    isOwner() {
      var userBots = this.$store.main.getters.userBots;
      return userBots.includes(this.item.bot);
      // return this.item.bot === this.$store.main.userId;
    },
    isBookmarked() {
      return this.$store.main.getters.bookmarks.filter((id) => id == this.item.id).length ? true : false;
      //return this.$store.main.getters.bookmarks.includes(this.item.id);
    },

    dropDownItems() {
      var item = this.item;
      if (!item) return [];

      return [
        {
          t: "Add to Bookmarks ", // "Add post to bookmarks",
          icon: "far fa-bookmark",

          hidden: this.isBookmarked,
          click: () => {
            //action in store
            this.$store.main.dispatch("addBookmark", this.item.id).then(() => {
              //
            });

            window.API.me.bookmarkPost({ postId: this.item.id }).then((res) => {
              this.$buefy.snackbar.open({
                message: "Post added to your Bookmarks",
                type: "is-success",
                position: "is-bottom",
                actionText: "view",
                onAction: () => {
                  this.$router.push("/bookmarks");
                },
                indefinite: false,
              });
              //this.item = null;
            });
          },
        },
        {
          t: "Bookmarked",
          icon: "fas fa-bookmark",

          hidden: !this.isBookmarked,
          click: () => {
            // alert(324235);
            /*
            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {*/
            // var img = this.item.featuredImage?.url300;
            //  alert(img);
            // window.API[method](d.handle, d).then((bot) => {
            this.$store.main.dispatch("removeBookmark", this.item.id).then(() => {
              //
            });
            window.API.me.unbookmarkPost(this.item.id).then((res) => {
              //TODO: if the current view is bookmarks, we need to remove the item from the list
              //emit event to parent
              this.$emit("bookmarkRemoved", this.item.id);
              this.$buefy.snackbar.open({
                message: "Post removed from your Bookmarks",
                type: "is-dark",
                position: "is-bottom",
                actionText: "Close",
                indefinite: false,
              });
            });
          },
        },

        {
          t: "View post",
          icon: "far fa-eye",
          to: this.item.url,
          hidden: this.isDetail,
        },
        /*
        {
          t: "View profile",
          visible: !this.isOwner,
          icon: "far fa-user",
          to: this.authorUrl,
        },*/
        {
          t: "Comments",
          icon: "far fa-comments",
          to: this.item.url,
          hidden: false,
        },

        {
          t: "Edit...",
          icon: "far fa-edit",
          hidden: !this.isOwner,
          to: this.item.url + "/edit",
        },
        {
          t: "Use as profile pic",
          icon: "far fa-image",
          hidden: !this.isOwner || !this.isImage,
          click: () => {
            // alert(324235);
            /*
            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {*/
            var img = this.item.featuredImage?.url300;
            //  alert(img);
            // window.API[method](d.handle, d).then((bot) => {

            window.API.editBot(this.item.bot, { avatar: img }).then((res) => {
              this.$buefy.snackbar.open({
                message: "Profile picture updated",
                type: "is-success",
                position: "is-bottom",
                actionText: "Close",
                indefinite: false,
              });
              //this.item = null;
            });

            //this.$buefy.toast.open('User confirmed')

            // });
          },
        },

        {
          t: "Flag for Re classification...",
          icon: "far fa-cogs",
          hidden: !this.isOwner,
          click: () => {
            // alert(324235);

            this.$buefy.dialog.confirm({
              message: "Request an image re-classiication?",
              type: "is-info",
              onConfirm: () => {
                window.API.editBotPost(this.item.bot, this.item.id, { flaggedForReclassify: true }).then((res) => {
                  this.$buefy.snackbar.open({
                    message: "reclassification requested",
                    type: "is-warning",
                    position: "is-bottom",
                    actionText: "Close",
                    indefinite: false,
                  });
                  this.item = null;
                });

                //this.$buefy.toast.open('User confirmed')
              },
            });
          },
        },

        {
          t: "Delete this post...",
          icon: "far fa-trash",
          hidden: !this.isOwner,
          click: () => {
            // alert(324235);

            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {
                window.API.editBotPost(this.item.bot, this.item.id, { deleted: true }).then((res) => {
                  this.$buefy.snackbar.open({
                    message: "Post deleted",
                    type: "is-warning",
                    position: "is-bottom",
                    actionText: "Close",
                    indefinite: false,
                  });
                  this.item = null;
                });

                //this.$buefy.toast.open('User confirmed')
              },
            });
          },
        },

        /*
        {
          t: "Email details...",
          icon: "far fa-info",
          click: () => {
            this.showMeta = !this.showMeta;
          },
        },
        {
          t: "Copy link",
          icon: "far fa-link",
          click: () => {
            this.copyLink(item);
          },
        },

        { t: "Open...", icon: "far fa-eye", to: this.getMsgDetailPath(item._id) },

        { t: "Gmail Fwd ", icon: "far fa-envelope", href: this.getMailtoHref(item, "fw") },
        { t: "Gmail Re ", icon: "far fa-envelope", href: this.getMailtoHref(item) },

        {
          t: "Info ",
          icon: "far fa-info-circle",

          click: this.toggleMeta,
        },
        {
          t: "Summarize  ",
          icon: "far fa-info-magic",

          click: this.getEmailSummary,
        },

        {
          t: this.format == "html " ? "Plain text" : "HTML",
          icon: "far fa-" + (this.format == "html " ? "file-alt" : "code"),

          click: () => {
            this.format = this.format == "html" ? "text" : "html";
          },
        },*/
      ];
    },

    hasSnipet() {
      return false; // this.item.code || this.item.action;
    },
    formatedPlainText() {
      // NO WORK!
      var t = this.item;
      return formatPlainTextInHtml(t);
    },
    //get current route
    isDetailModalActive() {
      return this.$route.params.messageId;
    },
    selectedEmail() {
      return this.messages.filter((message) => {
        console.log(message._id, this.$route.params.messageId, 5558874);
        return message._id === this.$route.params.messageId;
      })[0];
      // return this.$store.state.selectedEmail;
    },
    likes() {
      var ll = this.item.likes || [];
      // if (this.hasClickedLike) ll.push(this.$store.main.getters.userId);
      return ll;
    },
    isLiked() {
      if (this.hasClickedLike) return true;
      return this.likes.includes(this.$store.main.getters.userId);
    },
    likeCount() {
      //account for hasClickedLike
      //if (this.hasClickedLike) return this.likes.length + 1;
      return this.likes.length || 0;
    },
    images() {
      var images = this.item.images || [];
      images = images.map((i, index) => {
        i.style = `aspect-ratio: ${i.width}/${i.height}`;
        i.to = this.item.bot + "/p/" + this.item.id + "?image=" + index;
        return i;
      });
      return images;
    },
    featuredImage() {
      var images = this.images;
      if (images.length) return images[0];
      return null;
    },
  },
  methods: {
    clickImageInMosaic(img) {
      console.log("clickImageInMosaic 432423", img);
    },
    async clickShare() {
      this.$emit("clickShare");
      // use navigator.share if available
      // var text = `${window.location.origin}/${u}`;
      var u = this.item.url;
      var url = `${window.location.origin}${u}`;
      if (navigator.share) {
        try {
          var t = this.displayName;
          await navigator.share({
            title: "OnlyBots Invite Link",
            text: this.text,
            url: url,
          });
          this.$buefy.toast.open({
            message: "Shared successfully",
            type: "is-dark",
          });
        } catch (error) {
          console.error("Error sharing:", error);
          this.$buefy.toast.open({
            message: "Failed to share",
            type: "is-danger",
          });
        }
      } else {
        await navigator.clipboard.writeText(url);
        this.$buefy.toast.open({
          message: "Copied to clipboard",
          type: "is-dark",
        });
      }
    },
    clickComments() {
      //open inline comments
      this.inlineCommentsOpen = true;
      //set focus in comment input...
    },
    clickLike() {
      //like or unlike? checkstate
      var isLike = !this.isLiked;
      //call api
      this.loadingLikeButton = true;
      if (isLike) {
        window.API.likeBotPost(this.item.bot, this.item.id).then((res) => {
          console.log("like res", res);
          //update likes
          this.loadingLikeButton = false;
          this.hasClickedLike = true;
          //push into item.likes array
          this.item.likes.push(this.$store.main.getters.userId);
          //this.item.likes = res.data.likes;
        });
      } else {
        window.API.unlikeBotPost(this.item.bot, this.item.id, this.$store.main.getters.userId).then((res) => {
          console.log("unlike res", res);
          //update likes
          this.loadingLikeButton = false;
          this.hasClickedLike = false;
          //remove from item likes array
          this.item.likes = this.item.likes.filter((id) => id !== this.$store.main.getters.userId);

          //this.item.likes = res.data.likes;
        });
      }
    },
    clampchange(val) {
      //fires too often, see the watchedr for exapnded text
      /*
      if (val === false) {
        //fires twice on load...
        this.$emit("clampchange");
        alert(val);
      }*/
      //this.clamp = this.clamp == 0 ? 1 : 0;
    },

    clickToExpand() {
      // this.expanded = !this.expanded;
      if (this.expanded) this.expanded2 = true;
      if (!this.expanded) this.expanded = true;
    },
    toggleMeta() {
      this.showMeta = !this.showMeta;
    },
    getShareUrl(item) {
      return "/share/message/" + item._id + "/" + item.shareKey;
    },

    clickCode: function (item) {
      //console.log("click code", item);
      /*
      navigator.clipboard
        .writeText(this.item.code)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");
          this.copied = true;
          //disable after 3 seconds
          setTimeout(() => {
            this.copied = false;
          }, 3000);
          //toast
          // buefy toast
          this.$buefy.toast.open({
            message: "Code copied to clipboard",
            type: "is-success",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });*/
    },
    clickAddress(a, e) {
      //e.stopPropagation();
      /*
      navigator.clipboard
        .writeText(a)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");

          this.$buefy.toast.open({
            message: " Copied to clipboard  ✂️  " + a + " ",
            type: "is-dark",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
      return false;*/
    },

    copyLink(item) {
      console.log("copy link", item);

      //copy to clipboard
      // var host = window.location.hostname == "localhost" ? "http://localhost:4499" : "https://bye.email";
      var host = window.location.hostname == "localhost" ? "http://localhost:4499" : "https://bye.email";
      var txt = host + this.getShareUrl(item); //host + "/share/message/" + item._id + "/" + item.shareKey;

      navigator.clipboard
        .writeText(txt)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");

          //toast
          // buefy toast
          this.$buefy.toast.open({
            message: "Link copied to clipboard",
            type: "is-dark",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
    },
    itemDblCkick(id) {
      var to = this.getMsgDetailPath(id);
      this.$router.push(to);
    },
    //get file icon
    fileIconImg: function (filename) {
      var googleDocs = "https://cdn-icons-png.flaticon.com/512/5968/5968517.png";
      return googleDocs;
      //use a cloud service for icons images
      //https://www.iconfinder.com/iconsets/file-icons
      //https://www.iconfinder.com/iconsets/file-icons-2

      let ext = filename.split(".").pop();
      return "/img/file-icons/" + ext + ".png";
    },
    timeAgo: function (date) {
      return timeAgo(date);
    },
    closeModal: function () {
      //do reouter back previous url
      this.$router.go(-1);
      //this.$router.push({ name: "inbox" });
    },
    elipsis: function (str, len) {
      return elipsis(str, len);
    },
    //get messages from API
    async getEmailSummary() {
      // alert(32466);
      return 44;
      /*
      var t = String(this.item.parsed.text);
      // sanitize text, anonimize ppi and emails:
      //phone
      t = removeEmailHeaders(t);
      t = removeExtraSpacesAndLines(t);

      t = t.replace(/(\d{3})\d{3}(\d{4})/g, "$1***$2"); // phone

      //emails
      t = t.replace(/([\w.]*@[\w.]*\.[\w]{2,4})/g, "****@****.***");
      t = replaceUrls(t);
      t = truncateTextWords(t, 600);
      this.summerazing = true;
      window.API.summarizeText({ text: t, id: this.item._id })
        .then((data) => {
          this.summerazing = false;
          this.newSummary = data;
        })
        .catch((error) => {
          this.summerazing = false;
          console.log(error);
        });*/
    },
    closeBtn: function (id) {
      alert(id);
      //call API to delete message, flag it as "closed"
      // and collapse the message card
    },

    getMailtoHref(item, reOrFwd) {
      return 4;
    },

    getMsgDetailPath(id) {
      //depending on current base path, return the path to the message detail route
      // in vue get first parth of the path.
      var topPath = this.$route.path.split("/")[1];

      if (topPath == "all") return "/all/" + id;
      if (topPath == "promos") return "/promos/" + id;
      if (topPath == "updates") return "/updates/" + id;
      if (topPath == "team") return "/team/" + id;
      return "/home/" + id;
    },
    showMessage: function (item, index) {
      //use vue vars
      var itemCard = document.getElementById("item-card-" + index);
      var itemPane = document.getElementById("message-pane");
      var itemPreview = document.querySelector(".message-preview .content");
    },
  },
};
</script>

<style scoped>
.socialCounter {
  padding: 10px;
  justify-content: space-between;
}

.inlineComments,
.inlineComments > div {
  width: 100%;
}

.images img {
  width: 100%;
  background: #f1f1f1;
}
/**/
.item-header {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.authorName {
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.timeago {
  display: block;
  line-height: 10px;
  color: #aaa;
  font-size: 14px;
}
.content,
.item-text {
  color: #444;
  white-space: pre-line;
}
.att img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.item {
  /* height: 200px;*/

  margin-top: 18px;
  overflow: hidden;

  border: rgb(248, 248, 248) 1px solid;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
/*
  .item.expanded {
    max-height: 500px;
  }
  .item.expanded2 {
    max-height: 9600px;
  }
  */
.item .whitefill {
  max-height: 200px;
  transition: all 0.2s ease-in-out;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent 20px,
    rgba(0, 0, 0, 0.2) 40px,
    rgba(0, 0, 0, 0.8) 80px,
    red 100px
  );
  /*
    -webkit-mask-image: linear-gradient(0deg, transparent 16px, red 66px);
    */
  /* 0deg = down, 90deg = left, 180deg = top, 270deg = right */
}
.item.expanded .whitefill {
  max-height: 600px;

  -webkit-mask-image: linear-gradient(0deg, transparent 16px, red 120px);
}

.item.expanded2 .whitefill {
  height: auto;
  max-height: none; /* need to be pixels if we want to transtion - we dont */
  transition: all 0.3s linear;
  -webkit-mask-image: none;
}

.item.read {
  border: #00caff00 2px solid;
}

/*  on mobile, educe padding of .item  */
@media screen and (max-width: 768px) {
  .item .card-content {
    padding: 4px 4px !important;
  }
  .item {
    border-radius: 2px !important;
  }
}

.item .card-content {
  padding: 8px;
}

.sniped.code {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 70px;
  letter-spacing: 15px;
  background-color: #fafafa;
  border-radius: 20px;
  padding: 4px 20px;

  color: #333;
  cursor: pointer;
  display: inline-block;
  /* transition all props */
  transition: all 0.3s;
}
.sniped.code:hover {
  background-color: #eee;
}
.sniped.code.copied {
  /* green*/
  background-color: #e6ffe6;
}
.timeago {
  /*
  no wrap*/

  white-space: nowrap;

  text-overflow: ellipsis;
}
</style>
