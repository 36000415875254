<template>
  <div class="mosaic-container" :class="layoutClass">
    <router-link
      v-for="(image, index) in images.slice(0, 5)"
      :to="image.to"
      :key="index"
      :class="`mosaic-item item-${index + 1}`"
    >
      <img :src="image.url" :alt="image.alt + averageRatio" />
      <div v-if="images.length > 5 && index === 4" class="more-overlay">+{{ images.length - 5 }} photos</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    averageRatio() {
      return this.images.reduce((acc, image) => acc + image.ratio, 0) / this.images.length;
    },
    layoutClass() {
      if (!this.images?.length) return "";
      switch (this.images.length) {
        case 1:
          return "layout-1";
        case 2:
          return this.averageRatio > 1 ? "layout-2-row" : "layout-2-col";
        case 3:
          return "layout-3-col";
          // WIP, issues with the row layout in pure css...
          // maybe we could a swaure layout. A tripple stack for 3 verticals or 3 very wide. etc.
          return this.averageRatio >= 1 ? "layout-3-row" : "layout-3-col";
        case 4:
          return "layout-4";
        case 5:
          return "layout-5";
        default:
          if (this.images.length > 5) {
            return "layout-5 layout-5-plus";
          }
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
.mosaic-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3px; // This will add spacing between the flex items - if it flexes too large it breaks...

  .mosaic-item {
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .more-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3); // Black with 70% opacity
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; // White text
    font-size: 16px; // Adjust as needed
    font-weight: bold; // Adjust as needed
  }

  &.layout-1 {
    .mosaic-item {
      max-height: calc(80vw * 4 / 5);
    }
  }

  &.layout-2-row,
  &.layout-3-row {
    flex-direction: column;
  }

  &.layout-2-col,
  &.layout-3-col,
  &.layout-4,
  &.layout-5 {
    flex-direction: row;
  }

  &.layout-2-row,
  &.layout-2-col {
    .mosaic-item {
      flex: 1;
    }
  }

  /*
  &.layout-3-row {
    .mosaic-item {
      &:nth-child(1) {
        flex: 100%;
      }
      &:nth-child(n + 2) {
        flex: 49%;
      }
      img {
        &:nth-child(1) {
          aspect-ratio: 2/3;
        }
        &:nth-child(n + 2) {
          aspect-ratio: 2/3;
        }
      }
    }
  }*/
  &.layout-3-row {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .mosaic-item {
      &:nth-child(1) {
        //flex: 2; // take up half the container width
        height: 100%; // take up full height
        flex-basis: 66%;
      }

      &:nth-child(2) {
        //  flex: 1; // take up 20% of the container width
        flex-basis: 33%;
        height: 30%;
        //  height: calc(30% - 4.5px); // take up half the height minus half the gap
        // order: 1; // place it before the third image
      }

      &:nth-child(3) {
        //  flex: 1; // take up 20% of the container width
        flex-basis: 33%;
        height: 30%;
        //height: calc(30% - 4.5px); // take up half the height minus half the gap
        //   order: 2; // place it after the second image
        // align-self: flex-end; // align it to the bottom
      }
    }
  }

  &.layout-3-col {
    .mosaic-item {
      &:nth-child(1) {
        flex: 100%;
      }
      &:nth-child(n + 2) {
        flex: 49%;
      }
      img {
        &:nth-child(1) {
          aspect-ratio: 3/2;
        }
        &:nth-child(n + 2) {
          aspect-ratio: 3/2;
        }
      }
    }
  }

  &.layout-4 {
    .mosaic-item {
      flex: 50%;
      flex: 49%; // Implicitly 1% margin
    }
  }

  &.layout-5 {
    .mosaic-item {
      &:nth-child(-n + 2) {
        flex: 50%;
        flex: 49%; // Implicitly 1% margin
      }
      &:nth-child(n + 3) {
        flex: 33.33%;
        flex: 32.33%; // Implicitly allow around 1.33% margin between items
      }
    }
  }
}
</style>
