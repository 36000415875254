<template>
  <div :class="{ inline: inline }">
    <!--   :mobile-modal="false"   still visible...
  
  Ok... Problem is that that the CHANGE event don't fire...
  
  
  
  WORKING:
  :position="position || 'is-bottom-left'"
  :triggers="['hover']"
  :focusable="false"
  class="newMenuModal333"
  :disabled="isMobile()"
  has-link
  
  v-on:active-change="cardOpened"
  v-on:change="cardOpened"
  
  
    :focusable="false"
  :triggers="['hover']"
    :disabled="isMobile()"
      :triggers="['hover']"
        has-link
          :position="position || 'is-bottom-right'"
            :disabled="true"
            xxxxxx
  -->

    <div v-if="user != undefined && disableForOwner && user == this.$store.main.getters.shortid">
      <!-- the card is disabled sometimes for own user. no need to re-show again and again...-->

      <slot>Fallback.... Need to pass something as SLOT</slot>
    </div>

    <b-dropdown
      v-else
      :mobile-modal="false"
      :position="position || 'is-bottom-left'"
      has-link
      class="newMenuModal333"
      :triggers="['hover']"
      :focusable="false"
    >
      <template #trigger has-link>
        <div v-on:mouseover="mouseOver" @mouseleave="mouseLeave">
          <!--    v-on:mouseover="mouseOver" @mouseleave="mouseLeave"  -->
          <router-link :to="profile.url" v-if="profile && profile.url">
            <slot>Fallback....</slot>
          </router-link>

          <slot v-else>Fallback....</slot
          ><!-- still show slot when stuff is NOT loaded...-->
        </div>
      </template>

      <b-dropdown-item custom aria-role="listitem" v-if="!isMobile()" class="is-hidden-mobile" style="text-align: left"
        ><!--  is mobile works, but not on resided display. failproof. --->
        <div class="carduseless" style="width: 300px; height: 170px; color: #333 !important">
          <!-- top row -->
          <div class="columns is-vcentered is-mobile" style="margin: 0">
            <div class="column is-narrow">
              <router-link :to="profile.url" v-if="profile && profile.url">
                <img
                  :src="profile.avatar"
                  loading="lazy"
                  class="squircle avatar"
                  style="width: 70px; height: 70px; margin: 0"
                />
              </router-link>
            </div>
            <div class="profile__container column">
              <router-link :to="profile.url" v-if="profile && profile.url">
                <h4 class="profile__name" style="font-size: 20px; font-weight: bold">
                  {{ profile.name }}

                  <!--<sup v-if="profile.isVerified">
                                    <span class="icon">
                                      <i class="fas fa-badge-check has-text-info"></i>
                                  </span>
                                </sup> -->
                </h4>
                <h4 v-if="profile.handle">
                  @{{ profile.handle }} <verifiedBadge v-if="profile.isVerified"></verifiedBadge>
                </h4>
                <span class="tag is-info is-small is-light" v-if="isHuman"> Human </span>
                <span class="tag is-danger is-small is-light" v-else> Bot </span>
              </router-link>
            </div>
          </div>
          <!--
  
    <div class="tag" v-if="profile && profile.isFollowingMe && profile.urlFollowers" >🎉 Follows you! </div>
  -->
          <router-link :to="profile.urlFollowers" class="underlineHover" v-if="profile && profile.urlFollowers">
            <span v-if="haveHovered && profile && profile.shortid"
              ><!-- this component just trigger tons of calls... -->
              <span
                ><strong><followersCount :id="profile.shortid" /></strong> Followers</span
              >
            </span>
          </router-link>

          <span class="sepDot"> • </span>

          <router-link :to="profile.urlFollowing" class="underlineHover" v-if="profile && profile.urlFollowing">
            <span>
              <strong><numAbv :num="profile.nbFollowing" /> </strong> Following</span
            >
          </router-link>
          <br />

          <div class=" " style="margin-top: 10px">
            <div class="columns is-vcentered is-mobile" v-if="!profile || !isOwner(profile.shortid)">
              <div class="column">
                <followButton :id="profile.shortid" expanded v-if="profile"> </followButton>
              </div>
              <div class="column">
                <router-link :to="profile.messageUrl" class="  " v-if="profile && profile.messageUrl">
                  <b-button icon-left="comment" type="is-info   is-outlined" icon-pack="fal" expanded>
                    Message
                  </b-button>
                </router-link>
              </div>
            </div>
            <div class="tag" v-else>That's you!</div>
          </div>

          <!-- bottom actions row -->

          <!--
                 {{isMobile()}}.isMobile.<br>
                    <img :src="profile.avatar" loading="lazy" class="squircle" style="  width:80px; height:80px;">
  
                    <h4>{{ profile.name }}</h4>
                    <br>
                    <h3 class="content pre-line">{{profile.title}}</h3>
                    <p class="content pre-line">{{profile.intro}}</p>
  
                    <router-link  :to="profile.url"  v-if="profile && profile.url">
                      {{isMobile()}}.isMobile.<br>
  
  
                         <img :src="profile.avatar" loading="lazy" class="squircle" style="  width:60px; height:60px;">
  
                     </router-link>
   -->
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
// dev

//var tt = ExtractASIN('http://www.amazon.com/Kindle-Wireless-Reading-Display-Generation/dp/B0015T963C/ref=amb_link_86123711_2?pf_rd_m=ATVPDKIKX0DER&pf_rd_s=center-1&pf_rd_r=0AY9N5GXRYHCADJP5P0V&pf_rd_t=101&pf_rd_p=500528151&pf_rd_i=507846');
//alert(tt);
//import Layout from '@/components/layout/LayoutModal.vue';
import followersCount from "@/components/e/followersCount.vue";
import followButton from "@/components/e/followButton.vue";
import numAbv from "@/components/e/numAbv.vue";
import verifiedBadge from "@/components/e/verifiedBadge.vue";
//import NewPostMenuItems from '@/components/nav/newPostMenuItems';
export default {
  name: "Home",
  components: {
    followersCount,
    verifiedBadge,
    followButton,
    numAbv, //NewPostMenuItems
  },
  data() {
    return {
      // beLazy: false,
      //  value: null,
      //discussions: [],
      //related: [],
      //comments: [],
      profile: {},
      isHover: false, //unreliable
      haveHovered: false,
      loading: true,
      //p:{},
      //hover:false,
    };
  },
  //props: ["inline", "user", "hideName", "stack", "hideActive", "profileData", "position", "disableForOwner"],
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    user: {
      type: String,
      default: null,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    stack: {
      type: Boolean,
      default: false,
    },
    hideActive: {
      type: Boolean,
      default: false,
    },
    profileData: {
      type: Object,
      default: null,
    },
    position: {
      type: String,
      default: null,
    },
    disableForOwner: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    /* '$route': function (from, to) {
          this.fetchMain()
       },*/
    /*'user': function(newVal, oldVal) { // watch it
            console.log('user prop change in provileAvatar.vue changed: ', newVal, ' | was: ', oldVal)
            this.fetchMain()
          }*/
  },
  mounted() {
    /* to be executed when mounted */
    // this.post = {};
    if (this.profileData) {
      this.profile = this.profileData; //fast show of current user avatar....
      this.loading = false;
    } else {
      //wait for hover instead baby!
      // if (!this.profile) this.fetchMain(); //no parsed data...
      //this.fetchMain();
    }
  },
  updated() {
    //fire on route change - all of em!
    //this.loadPost()
    //   this.fetchMain()
  },
  methods: {
    fetchMain(event) {
      //called on mouseHover!
      // Issue; this is called on initial load....
      let userIdentifier;
      //this.post = {};
      this.loading = true;
      if (this.profileData) {
        //this.user = this.profileData.shortid
        userIdentifier = this.profileData.shortid;
      } else {
        //   this.user = this.user; //allready the case
        userIdentifier = this.user;
      }

      console.log("fetching data for hover card....... for user:", userIdentifier);
      //alert(userIdentifier)

      var reqParams = this.$store.main.getters.userId ? { followingUser: this.$store.main.getters.userId } : {};

      window.API.userProfile(userIdentifier, reqParams).then((res) => {
        //...

        this.profile = this.p = res;
        this.loading = false;
      });
    },
    isMobile() {
      //kinda garbage check...
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    /*
     cardOpened(active){
       console.log('cardOpenedcardOpened')
        console.log(active)
     },*/
    mouseOver(active) {
      console.log("mouseOver", active);
      console.log("mouseOver");
      this.isHover = true;
      this.haveHovered = true;
      if (!this.isMobile()) this.fetchMain();

      return true;
    },
    mouseLeave(active) {
      console.log("mouseLeave");
      console.log("mouseLeave", active);
      this.isHover = false; //not reliable for the card...
      return true;
    },
    isOwner: function (i) {
      return this.$store.main.getters.userId == i;
    },
  },

  computed: {
    isHuman() {
      return this.user ? true : false;
    },
  },
};
</script>
<style scope>
.profile__container {
  overflow: hidden;
}

.profile__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.inline {
  display: inline;
}
</style>
