<template>
  <div>
    <div
      v-if="layout == 'list'"
      class="feed-wrap"
      style="max-width: 680px; margin: auto; display: block; padding-bottom: 40px"
    >
      <!--  
      <bot-card
        v-for="bot in visibleBots"
        :key="bot.handle"
        :bot="bot"
        :include-links="includeLinks"
        class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd"
      ></bot-card>
    
      {{ visibleItems.length }}
    -->

      <postCard
        v-for="item in visibleItems"
        :key="item.id"
        :item="item"
        :include-links="includeLinks"
        context="list"
        class="d"
        :canClose="canClose"
        @bookmarkRemoved="$emit('bookmarkRemoved', $event)"
      />
    </div>
  </div>
</template>

<script>
import BotCard from "./BotCard.vue";
import postCard from "./PostCard.vue";
//lodash
import _ from "lodash";

/*
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();
*/
export default {
  components: {
    // BotCard,
    postCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    infinite: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    includeLinks: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "list",
    },
  },
  data() {
    return {
      currentPage: 1,
      windowWidth: window.innerWidth,
      nbPostsShown: this.infinite ? 3 : 100,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("scroll", this.checkScroll);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.checkScroll);
  },

  //watch slide per vie wand trigger swiperEl.slidesPerView = 3
  watch: {
    slidesPerView(a, b) {
      console.log("slidesPerView changed", a, b);
      //const swiperEl = document.querySelector("swiper-container");
      this.$refs.mySwiper.swiper.update();
    },
  },
  computed: {
    slidesPerView() {
      //determine based on screen size how many cards to show
      if (this.windowWidth < 768) {
        return 2;
      } else if (this.windowWidth < 1024) {
        return 3;
      } else if (this.windowWidth < 1280) {
        return 3;
      } else if (this.windowWidth < 1440) {
        return 4;
      } else {
        return 4;
      }
    },
    visibleItems() {
      var bots = this.items;
      bots = _.compact(bots);
      // const startIndex = (this.currentPage - 1) * this.pageSize;
      //const endIndex = startIndex + this.pageSize;
      // console.log("fafs", this.nbPostsShown);
      //return only x posts
      return bots.slice(0, this.nbPostsShown);
    },
    totalPages() {
      return Math.ceil(this.bots.length / this.pageSize);
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    checkScroll() {
      const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 500;
      if (nearBottom) {
        this.loadMore();
      }
    },
    loadMore() {
      //incremetn posts shown
      this.nbPostsShown += 3;
      //emit event to parent
      this.$emit("loadMore"); //not used now

      //   alert(3);
      //   this.createdNbShown += 6;
      //   this.loadBots();
    },

    prev() {
      const swiperEl = document.querySelector("swiper-container");
      this.$refs.mySwiper.swiper.slidePrev();
      // swiperEl.swiper.slidePrev();
    },
    next() {
      const swiperEl = document.querySelector("swiper-container");
      //swiperEl.swiper.slideNext();
      this.$refs.mySwiper.swiper.slideNext();
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
};
</script>
<style scoped>
.title-and-nav,
.title-and-nav .column {
  padding-bottom: 0;
  align-items: flex-end;
}
</style>
